.healthcare-landing{
  h1{
    text-transform: unset;
    font-family:'AvantGardeGothicITCW01M_731087';
  }
  h2{
    text-transform: unset;
    font-family:'AvantGardeGothicITCW01M_731087';
    font-size: 28px;
  }
  h3{
    color:#53c0dd;
    text-transform: unset;
  }

  p{
    font-family: AvantGardeGothicITCW01M_731087;
  }


  iframe{
    width:100%;
    height:470px;
  }


  .list-block{
    padding-top: 50px;
    clear: both;
    overflow: hidden;
  }

  .feature-wrapper{
    height: 250px;
    width: 100%;
    display: inline-block;
    position: relative;
    overflow: hidden;
  }

  .col-md-6{
    padding-bottom: 50px;

    &:nth-child(2n+1){
      padding-right:55px;

    }

    &:nth-child(2n){
      padding-left:55px;
      padding-right:0;
    }

    .read-more{
      font-family: 'AvantGardeGothicITCW01B_731069';
    }

    a{
      display: inline-block;
    }
  }

  .bottom-intro{
    padding-top:30px;
    padding-bottom:130px;
  }
}


@media (max-width: 991px) {
  .healthcare-landing{
    iframe{
      height:340px;
    }

    .col-md-6{
      padding-bottom: 30px;

      &:nth-child(2n+1){
        padding-right:15px;
        padding-left:15px;
      }

      &:nth-child(2n){
        padding-left:15px;
        padding-right:15px;
      }
    }

    .bottom-intro{
      padding-top:10px;
      padding-bottom:30px;
    }

  }
}