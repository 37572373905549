@media (min-width: 991px) {
  .nav.navbar-nav{
    display: inline-flex;

    li{
      width:unset;
      text-align: left;

      a{
        padding: 10px 2px;
        font-family: 'AvantGardeGothicITCW01B_731063';

        i{
          color:#53c0dd;
        }
      }
    }
  }
}

@media (min-width: 1200px){
  .container.nav-bar {
    border-top: none;
  }

  .container>.navbar-header{
    border-top: solid 3px  rgba(0,0,0,0.1);
  }

  .navbar-collapse {
    padding: 0;

  }
  .navbar-inverse{
    .navbar-nav{
      padding-right:0;

      >li{

        a{
          padding: 10px 25px;
        }

        text-align: left;

        &:first-of-type{
          a{
            padding-left:0;
          }

        }

        &:nth-last-child(2){
          a{
            padding-right: 0;
          }

        }



      }

    }
  }
}

