body, html {
  overflow-x: hidden;
  font-size: 16px;
  position: relative;
}

html {
  min-height: 100%;
}

body {
  //font-size: 17px;
  -webkit-font-smoothing: antialiased;

}

.bg-lightblue{
  background-color:#DDF2F8;

  .navbar-inverse{
    background-color: #DDF2F8;
    border-color: #DDF2F8;
  }

  .navbar-inverse .navbar-nav>.open> a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus{
    background-color: #DDF2F8;
  }
}

.nav-list{
  h4{
    font-family: 'AvantGardeGothicITCW01M_731087';
  }
  #side-nav .subnav-item{
    a{
      font-size:15px ;
      font-family: 'AvantGardeGothicITCW01M_731087';
    }

    li {
      padding-top: 10px;
    }
  }
}

.nav-header .fa-ul > li{
  border-color: rgba(0,0,0,0.1);
}