.healthcare-banner{
  height:210px;
  background: #53c0dd;
  margin-bottom: 80px;

  .container{
    position: relative;
  }

  .banner-img{
    height:200px;
    position: absolute;
    bottom:0;
    right:0;
  }

  .banner-text{
    display: inline-table;
    vertical-align: middle;
    height: 210px;

    h2,.fa{
      color:#fff;
      padding: 0;
      margin: 0;
      text-transform: none;
      vertical-align: middle;
      display: table-cell;
      font-size: 28px;
    }

    h2{
      font-family:'AvantGardeGothicITCW01M_731087';
    }

    .fa{
      padding-left:10px
    }
  }

}


@media screen and (max-width: 1200px){
  .healthcare-banner{
    .banner-img{
      margin-left:0;
      margin-right:10px;
    }
  }

}

@media screen and (max-width: 991px){
  .healthcare-banner{
    height: auto;
    margin-bottom: 30px;

    .banner-img{
      height:120px;
      width:auto;
      position: relative;
    }

    .banner-text{
      height: auto;
      padding: 20px 0;

      .fa,h2{
        display: inline;
      }
    }
  }
}

