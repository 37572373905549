/*
Theme Name: NZGBC
Author: Joel Stewart
Description: Theme for Transformer Desgin LTD
Version 1.0
License: GNU General Public License v3
Lisence URI: http://www.gnu.org/licenses/gpl-3.0.html
Text Domain: Transformerdesign.co.nz

This theme has been created for Transformer Design LTD

*/


//--- Difining Variables



//Global

@import '_global';



//------ Inlcudes
@import "_includes/healthcare-banner";



//------ Components
@import "_components/nav";
@import "_components/editor-general";

//--- Page
@import 'pages/homepage';
@import 'pages/healthcare-landing';



//Difining Variables
@import 'bootstrap/_variables';
@import 'bootstrap/_mixins';



//Global 
@import '_global';


//needs to be last to over write




