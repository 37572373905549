h3{
  font-size: 20px;
}

p.aspect-ratio{
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

p.info-line{
  background: #fff;
  border-radius: 10px;
  padding: 15px 40px 15px 45px;
  font-size: 13px;

  position: relative;
  font-family: 'AvantGardeGothicITCW01D_731075' !important;

  &:before{
    content:" ";
    width:20px;
    height:20px;
    display: block;
    background:url('../../../../img/info-circle-solid.svg');
    position: absolute;
    top:17px;
    left:15px;

  }

}

p.large-text{
  color:#23195f;
  font-family: 'AvantGardeGothicITCW01M_731087';
  font-size: 21px;
  max-width: 620px;
}

ul.healthcare{
  list-style-type: none;
  padding-left:15px;


  li{
    font-family: 'AvantGardeGothicITCW01B_731063';
    position: relative;
    padding-left:30px;
    line-height: 26px;
    padding-top:10px;
    padding-bottom:10px;
    position: relative;

    &:before{
      position: absolute;
      content:" ";
      //width:14px;
      //height:14px;
      //display: block;
      //background-repeat: no-repeat;
      //position: absolute;
      //left:-6px;
      //top:17px;
      //z-index: 3;
      font-size: 16px;
      font-family: fontawesome;
      background-color:#23195f;
      color:#fff;
      width: 30px;
      height: 30px;
      text-align: center;
      border-radius: 50%;
      line-height: 30px;
      top:8px;
      left: -10px;
      display: block;
    }

    //&:after{
    //  content:" ";
    //  width:24px;
    //  height:24px;
    //  display: block;
    //  background-color:#23195f;
    //  border-radius: 50%;
    //  position: absolute;
    //  left:-10px;
    //  top:12px;
    //  z-index: 2;
    //}
  }

  &.color-blue{
    li{
      color:#53c0dd;
    }
  }

  &.color-purple{
    li{
      color:#25276f;
    }
  }

  &.short-length{
    max-width: 620px;
  }

  &.list-check{
    li{

      &:before{
        //background-image: url(../../../../img/check-solid.svg);
        //background-position: 1px center;
        content:"\f00c";
      }
    }
  }

  &.list-say{
    li{
      &:before{
        //background-image: url(../../../../img/comment-solid.svg);
        //background-position: 1px center;
        content:"\f075";
      }
    }
  }

  &.list-number{
    counter-reset: my-awesome-counter;
    li{
      counter-increment: my-awesome-counter;

      &:before{
        content: counter(my-awesome-counter);
        font-family: 'AvantGardeGothicITCW01B_731063';
        //background-position: 1px center;
        //font-size: 18px;
        //color:#fff;
        //top:11px;
        //text-align: center;
        //width: 24px;
        //height: 24px;
        //left: -10px;
      }
    }
  }

  &.list-arrow{
    li{
      &:before{
        content:"\f178";
      }
    }
  }

  &.list-qa{
    li:nth-child(2n+1){
      padding-bottom: 0;

      &:before{
        content: 'Q';
        font-family: 'AvantGardeGothicITCW01B_731063';
        //background-position: 1px center;
        //font-size: 17px;
        //color:#fff;
        //top:11px;
        //left:-5px;
      }

    }

    li:nth-child(2n){
      margin-bottom: 20px;
      font-family: AvantGardeGothicITCW01M_731087;
      color:#333;
      
      &:before{
        content: 'A';
        //font-size: 16px;
        //color:#23195f;
        //top:11px;
        //left:-4px;
        font-family: 'AvantGardeGothicITCW01B_731063';
        color: #23195f;
        background: #fff;
      }
      //&:after{
      //  background-color:#fff;
      //}
    }
  }
}

a.button{
  border:none;
  border-radius: 20px;
  padding:10px 25px;
  font-family: 'AvantGardeGothicITCW01D_731075';
  color:#fff;
  margin-right:20px;
  font-size: 15px;
  margin-bottom: 20px;
  display: inline-block;

  &.light{
    background:#53c0dd;

    &:hover{
      background:#fff;
      color:#53c0dd;
    }
  }

  &.dark{
    background:#23195f;
    &:hover{
      background:#fff;
      color:#23195f;
    }

  }
}
