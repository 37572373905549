.homebanner{
  height:110px;
  background: #53c0dd;
  position: relative;

  .banner-img{
    height:120px;
    margin-top: -10px;
    margin-left:60px;
    margin-right:30px;
  }

  a:hover{
    cursor: pointer;
  }

  .banner-text{
    display: inline-table;
    vertical-align: middle;
    height: 110px;

    &:hover{
      h2,.fa{
        color:#292567;
      }
    }

    h2,.fa{
      color:#fff;
      padding: 0;
      margin: 0;
      text-transform: none;
      vertical-align: middle;
      display: table-cell;
      font-size: 24px;
    }

    h2{
      font-family:'AvantGardeGothicITCW01M_731087';
    }

    .fa{
      padding-left:10px;

    }
  }

}

.membership-banner {
  background-color: #292567;
  width: 100%;

  .item-title {
    display: none;
  }

  .fixed-text {
    background-color: rgba(0,0,0,0.2);
    height: 100%;
    padding: 38px 45px 70px 45px;

    .text {
      padding-top: 50px;
    }

    .logo-container {
      text-align: right;
    }

    img {
      width: 43px;
    }

    h3 {
      color: #F6F6F6;
      text-transform: none;
      font-size: 18px;
      line-height: 1.67em;
      font-weight: normal;
      width: 100%; 
      padding: 0;
      margin: 0 0 45px 0;
    }

    button {
      color: #36A3BF;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 0.036em;
      background-color: white;
      border-radius: 30px;
      border: 0;
      padding: 18px 44px;
      font-weight: bold;
    }
  }

  ul {
    padding: 0;
  }

  li {
    list-style: none;
    color: #fff;

    p {
      color: #fff;
    }
  }

  .carousel {
    padding-right: 60px;
    p {
      color: #fff;
      font-size: 16px;
      line-height: 1.875em;
    }

    .item a p {
      font-family: 'Marydale W01 Regular';
      color: #53C1DD;
      font-size: 24px;
      letter-spacing: 0.025em;
    }
  }

  .carousel-indicators {
    position: relative;
    top: auto;
    left: auto;
    margin: 0;
    bottom: auto;
    width: 100%;
    text-align: left;
    padding-bottom: 30px;

    li {
      list-style: none;
      color: #fff;
      width: auto;
      text-indent: initial;
      height: auto;
      margin: 0;
      background-color:transparent;
      border: 2px solid rgba(255,255,255,0.1);
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-radius: 0;
      font-size: 14px;
      letter-spacing: 0.029em;
      font-weight: bold;
      line-height: 20px;
      padding: 0 15px;
      margin: 0 10px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      &:after {
        content: '';
        display: block;
        padding-bottom: 22px;
      }

      &.active {
        border-color: rgba(255,255,255,1);
        color: #53C0DD;
      }
    }
  }
}

.banner-title {
  font-size: 10px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: rgba(255,255,255, .8);
  margin: 44px 0 30px 0;
  padding: 0;
}

/**
  START Home Page Cards Section
 */
//@keyframes scale {
//  to {
//    transform: scale(1.1);
//  }
//}
@keyframes arrow-move {
  to {
    right: 20px;
  }
}
@keyframes arrow-line-move {
  to {
    right: 25px;
    opacity: 1;
  }
}

.home-cards{
  padding: 70px 0 50px 0;
  margin-bottom: -30px;
  background: #E6F6FA;
}

.cards-container{
  margin-bottom: 20px;

  &:last-of-type{
    margin-bottom: 0;
  }

  .feature-card{
    border-radius: 10px;
    //background: radial-gradient(circle, #114E9F 0%, #53C1DD 100%, #53C1DD 100%);
    background: radial-gradient(circle at 66% 0%, #114E9F 0%, #53C1DD 85%, #53C1DD 100%);
    margin: 0 -6px;
    overflow: hidden;

    .image-wrapper{
      height: 210px;
      width: 100%;
      position: relative;
      overflow: hidden;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      transform: scale(1);
      transition: transform 0.4s;
      transition-timing-function: cubic-bezier(0.12,0.75,0.4,1);

      img{
        position: absolute;
        min-height: 100%;
        display: block;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        min-width: 100%;
      }
    }

    .text-wrapper{
      min-height: 126px;
      font-size: 18px;
      padding: 20px 0;
      color:#fff;
      font-family:'AvantGardeGothicITCW01B_731063';
      text-shadow: rgba(0,0,0,0.2) 0 0 10px;
      position: relative;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .text{
        padding-left: 30px;
        padding-right: 90px;
      }

      &:before{
        content:'';
        position: absolute;
        width:20px;
        height:3px;
        background: #fff;
        top: calc(50% + 1px);
        transform: translateY(-50%);
        opacity: 0;
        right:50px;
        border-radius: 1px;

        transition: right 0.4s,opacity 0.4s;
        transition-timing-function: cubic-bezier(0.12,0.75,0.4,1);
      }

      &:after{
        content: url('../../../../img/arrow-right.svg');
        display: block;
        position: absolute;
        right:30px;
        top:calc(50% + 4px);
        transform: translateY(-50%);
        width:12px;

        transition: right 0.4s;
        transition-timing-function: cubic-bezier(0.12,0.75,0.4,1);

      }


    }

    &:hover{
      .image-wrapper{
        cursor: pointer;
        transform: scale(1.1);
      }

      .text-wrapper{
        &:before{
          //animation: arrow-line-move 0.4s ease-in-out forwards;
          opacity: 1;
          right:25px;
        }

        &:after{
          //animation: arrow-move 0.4s ease-in-out forwards;
          right:20px;
        }


      }
    }
  }

  &.general-cards{
    .feature-card{
      background: radial-gradient(circle at 66% 0%, #1B0E39 0%, #292567 85%, #292567 100%);
    }
  }

}


@media screen and (max-width: 1200px){
  .homebanner{
    .banner-img{
      margin-left:0;
      margin-right:10px;
    }
  }

  .membership-banner {
    .carousel-inner {
      margin-top: 45px;
    }
    .carousel {
      p {
        font-size: 14px;
      }

      .item a p {
        font-size: 20px;
      }
    }

    .carousel-indicators li {
      width: 28%;
      margin: 0 4%;
      float: left;

      &:after {
        padding-bottom: 12px;
      }
    }
    .fixed-text {
      padding-left: 25px;
      padding-right: 25px;

      .text {
        padding-top: 20px;
      }

      button {
        padding: 18px 15px;
      }


    }
  }


}


@media screen and (max-width: 991px){
  .homebanner{
    height: auto;

    .banner-img{
      height: auto;
      width: 100%;
    }

    .banner-text{
      height: auto;
      padding: 20px 0;

      .fa,h2{
        display: inline;
      }
    }
  }

  .membership-banner {
    .fixed-text {
      left: -15px;
      width: calc(100% + 30px);

      button {
        width: 100%;
      }
    }
    .carousel {
      padding-right: 0;
    }
    .carousel-indicators li {
      text-indent: -999px;
      line-height: 0;
      width: 30%;
      margin: 0 2%;
    }

    .carousel-inner {
      margin-top: 0;
    }

    .item-title {
      color: #fff;
      display: block;
      font-size: 14px;
      text-transform: none; 
      font-weight: bold !important;
    }
    .banner-title {
      margin: 40px 0 15px 0;
    }
  }


  //Home Page Cards Section
  .home-cards{
    padding-top:30px;
    margin-bottom: -30px;
    background: #E6F6FA;
  }

  .cards-container {
    margin-bottom: 0;

    .feature-card {
      margin: 0 5px 20px 5px;

      .text-wrapper{
        min-height: unset;
      }
    }
  }
}

